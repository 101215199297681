import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { AppProps } from 'next/app';
import { browser, device } from '@jam3/detect';
import classnames from 'classnames';
import 'normalize.css';
import '@/utils/why-did-you-render';

import '@/styles/global.scss';

import Layout from '@/components/Layout/Layout';

import gsapInit from '@/utils/gsap-init';

import { store } from '@/redux';

const isBrowser = typeof window !== 'undefined';

if (isBrowser) {
  require('default-passive-events');
  require('focus-visible');
  gsapInit();
}

// This default export is required in a new `pages/_app.js` file.
function App({ Component, pageProps }: AppProps) {
  const { isUnsupported, ...componentProps } = pageProps;
  // const [isBrowserInApp, setisBrowserInApp] = useState(false);
  // const redirectRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (isBrowser) {
      // const userAgent = navigator.userAgent || navigator.vendor;

      // const isFacebookApp = () => {
      //   console.log('Facebook In App');
      //   return /FBAV|FBAN|FBIOS|FBOP/.test(userAgent);
      // };

      // const isInstagramApp = () => {
      //   console.log('Instagram In App');
      //   return userAgent.includes('Instagram') || userAgent.includes('INSTA');
      // };

      // if (/android/i.test(userAgent) && (isFacebookApp() || isInstagramApp())) {
      //   setisBrowserInApp(true);
      // }

      if (process.env.NODE_ENV !== 'production' && window.location.href.indexOf('?nostat') === -1) {
        require('@jam3/stats')();
      }

      document.body.className = `${document.body.className} ${classnames(device.type, browser.name, {
        'touch-device': device.touch
      })}`.trim();
    }
  }, []);

  // useEffect(() => {
  //   if (isBrowserInApp) {
  //     redirectRef?.current?.click();
  //   }
  // }, [isBrowserInApp]);

  // if (isBrowserInApp) {
  //   return (
  //     <a ref={redirectRef} download target="_blank" href="https://www.maratonabenesseredonna.it/">
  //       Attendi un attimo. Apertura sito
  //     </a>
  //   );
  // }

  if (isUnsupported) {
    return <Component {...componentProps} />;
  }

  return (
    <Provider store={store}>
      <Layout>
        <Component {...componentProps} />
      </Layout>
    </Provider>
  );
}

export default App;
