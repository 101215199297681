export type Route = {
  readonly path: string;
  readonly title: string;
};

export interface Routes {
  readonly Home: Route;
  readonly MaratonaBenessereDonna: Route;
  readonly MaratonaLatoB: Route;
  readonly Autovalutazione: Route;
}

const routes: Routes = {
  Home: {
    path: '/',
    title: 'Home'
  },
  MaratonaBenessereDonna: {
    path: '/maratona-benessere-donna',
    title: 'Maratona Benessere Donna'
  },
  MaratonaLatoB: {
    path: '/maratona-latob',
    title: 'Maratona LatoB'
  },
  Autovalutazione: {
    path: '/autovalutazione-della-diastasi',
    title: 'Autovalutazione Della Diastasi'
  }
};

export default routes;
